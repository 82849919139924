import React from 'react'
import NextHead from 'next/head'

const Head = ({ component }) => (
  <NextHead>
    <meta charSet="utf-8" />
    <title>{component.title || 'AI - 像专业人士一样创作'}</title>
    <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <meta
      name="keywords"
      content="GroundAI, AI免费聊天写作、AI绘画, AI聚合, AI大模型, AI聊天, AI写作, 文生图、图生图、Stable Diffusion、Midjourney、妙笔, 一键生图, GroundAI, groundai, AI聚合, AI大模型, 人工智能, ai对话,AI智能助手, AI软件, AI聊天, AI写作, AI创意, AI绘画, AIGC, AI创作工具, AI模型分享, AI工具, AI创作平台, AI图标、AI LOGO, AI头像、AI壁纸、AI艺术字。"
    />
    <meta name="description" content={component.description || 'GroundAI致力于打造下一代AIGC绘画写作聊天一体的综合平台，让用户可以更方便地使用AI工具，创作出更多优秀的作品。'} />
    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" href="/favicon.ico" />
  </NextHead>
)

export default Head
