import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Navbar, Button, NavbarItem, Image, NavbarBrand, NavbarContent } from '@nextui-org/react'
import dynamic from 'next/dynamic'
import { getToken, setToken } from '@/utils/request'
import { useProfile } from '@/swr/useProfile'
import BackToTop from './BackTop'

const HeaderDropdown = dynamic(() => import('@/components/HeaderDropdown'), { ssr: false })

const App = ({ need_login, data, children }) => {
  const router = useRouter()
  const { mutate } = useProfile()

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const shouldRedirectLogin = useMemo(() => need_login && !getToken(), [need_login])

  const menuItems = [
    ['我的图集', '/my/profile', 'profile'],
    ['支付', '/pricing', 'pricing'],
  ]

  useEffect(() => {
    if (shouldRedirectLogin) {
      router.replace(`/`)
    }
  }, [router, shouldRedirectLogin])

  useEffect(() => {
    if (router.query.token) {
      setToken(router.query.token)
      mutate().then((res) => {
        if (res.id) {
          router.replace(router.pathname)
        }
      })
    }
  }, [mutate, router, router.query.token])

  return (
    <div>
      <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="full" className="h-16">
        <NavbarContent justify="start">
          <NavbarBrand>
            <NavbarBrand className="flex items-center gap-3">
              <Image src="/logo.png" width={40} height={40} alt="" radius="full" onClick={() => router.push('/')} className="cursor-pointer" />
              <div className="hidden cursor-pointer font-bold text-inherit sm:block" onClick={() => router.push('/')} role="presentation">
                AI
              </div>
            </NavbarBrand>
          </NavbarBrand>
        </NavbarContent>
        <NavbarContent className="hidden lg:flex" justify="start">
          <NavbarItem>
            <Button
              disableRipple
              className={`${router.asPath === '/' ? 'text-primary' : ''} "bg-transparent p-0 data-[hover=true]:bg-transparent`}
              radius="sm"
              variant="light"
              onClick={() => {
                router.push('/')
              }}
            >
              首页
            </Button>
          </NavbarItem>
          {menuItems.map((v) => (
            <NavbarItem key={v[1]}>
              <Button
                disableRipple
                className={`${router.asPath.includes(v[2]) ? 'text-primary' : ''} "bg-transparent p-0 data-[hover=true]:bg-transparent`}
                radius="sm"
                variant="light"
                onClick={() => {
                  router.push(v[1])
                }}
              >
                {v[0]}
              </Button>
            </NavbarItem>
          ))}
        </NavbarContent>
        <NavbarContent justify="end" className="relative">
          <HeaderDropdown needLogin={need_login} data={data} />
        </NavbarContent>
      </Navbar>
      <BackToTop />
      {children}
    </div>
  )
}

export default App
