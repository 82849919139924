import fetch from 'isomorphic-unfetch'
import localStorage from 'localStorage'

// const API_ROOT = process.env.NEXT_PUBLIC_API_URL
const API_ROOT = 'https://api.taikuaile.one'

export const setToken = (s) => {
  return localStorage.setItem(`token`, `Bearer ${s}`)
}
export const getToken = () => {
  return localStorage.getItem('token')
}

export const clearToken = () => {
  return localStorage.removeItem('token')
}

const headers = () => {
  return {
    'Access-Control-Allow-Origin': '*',
    Authorization: getToken(),
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

const body = (data) => {
  return {
    ...data,
  }
}

const parseResponse = async (res) => {
  if (res.headers.get('Authorization')) {
    setToken(res.headers.get('Authorization'))
  }
  try {
    const json = await res.json()
    if (res.status >= 400) {
      json.status = res.status
    }
    if (res.status === 401) {
      clearToken()
    }

    return json
  } catch (error) {
    console.error(error)
  }
  return false
}

export const get = async (path) => {
  const params = body()
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  const url = `${API_ROOT}/${path}${query}`
  const res = await fetch(url, {
    method: 'GET',
    headers: headers(),
  })
  const content = await parseResponse(res)
  return content
}

const requestMethod = (method) => async (path, data) => {
  const url = `${API_ROOT}/${path}`
  const res = await fetch(url, {
    method,
    headers: headers(),
    body: JSON.stringify(body(data)),
  })
  const content = await parseResponse(res)
  return content
}

export async function requestImage2Image(path, init_image, image2image) {
  const url = `${API_ROOT}/${path}`
  const file = init_image[0]

  if (!(file instanceof File)) {
    throw new Error('init_image must be a file')
  }
  const formData = new FormData()
  formData.append('image2image', JSON.stringify(image2image))
  formData.append('init_image', file)

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: getToken(),
    },
    body: formData,
  })
  const content = await parseResponse(res)
  return content
}

const streamRequestMethod = (method) => async (path, data) => {
  const url = `${API_ROOT}/${path}`
  const res = await fetch(url, {
    method,
    headers: headers(),
    body: JSON.stringify(data),
  })
  return res
}

export const post = requestMethod('POST')
export const put = requestMethod('PUT')
export const httpDelete = requestMethod('DELETE')

export const streamPost = streamRequestMethod('POST')
