import { Button } from '@nextui-org/react'
import { debounce, omit } from 'lodash-es'
import React, { useMemo } from 'react'

const DebounceButton = (param) => {
  const { children, onClick, className, buttonRef } = param
  const others = omit(param, ['children', 'onClick', 'width', 'style', 'className'])
  const onClickThrottle = useMemo(
    () =>
      debounce(
        (e) => {
          if (onClick) onClick(e)
        },
        600,
        { leading: true, trailing: false }
      ),
    [onClick]
  )
  return (
    <Button ref={buttonRef} className={`${className}`} onClick={(e) => onClickThrottle(e)} {...others}>
      {children}
    </Button>
  )
}

export default DebounceButton
